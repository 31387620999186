import React from 'react';
import FAQ from '../components/FAQ';
import Playstore from '../components/Playstore';
import Footer from '../components/Footer';
import Header from '../components/Header';
import Main from '../components/Main';

function Home() {
  return (
    <div className='overflow-hidden'>
        <Header />
        <Main />
        <FAQ />
        <Playstore />
        <Footer />
    </div>
  )
}

export default Home