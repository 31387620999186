import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import TradeHeader from './TradeHeader';
import TradeBull from '../assets/images/trade-bull.svg';
import Axios from '../utils/axios';
import { render } from 'react-dom';
import { Triangle } from  'react-loader-spinner';


function Trade() {
  const [email, setEmail] = useState('');
  const [showOverlay, setShowOverlay] = useState(true);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (email.length && showOverlay) {
      setShowOverlay(false);
    }
  }, [email, showOverlay]);

  const handleOverLayClick = (evt) => {
    evt.preventDefault();
    setShowOverlay(false);
  };


  const handleSubmit = (e) => {
    e.preventDefault()
    setLoading(true)
    console.log(email)
    Axios.get('validate-email/', {
      params: {
        email
      }
    })
    .then( res => {
      if (res.status === 200) {
        console.log("success");
        navigate('/trading', { state: { email } });
        setLoading(false)
      }

    })
    .catch( err => {
      console.log( "error:", err?.response?.data?.details || 'Nothing to see');
      const errorMessage = err?.response?.data?.details;
      setLoading(false);
      setError(errorMessage) ;
    })



  }

  

  return (
    <>
      <TradeHeader />

      {showOverlay && <a className='tooltip-overlay' onClick={handleOverLayClick} />}

      <div id="Trade">
        <div className="container">
          <div className="row">
            <div className="col-10 col-sm-10 col-md-6 col-lg-6 m-auto">
              <div className='text-center trade-bull-container'>
                <img src={TradeBull} alt="trade bull" className="trade-bull text-center animate__animated animate__pulse animate__infinite" /> 
                <div className="trade-bull-header">Your Email Mr. Annonymous</div>
                <div className="trade-bull-text">Just so you can get notified.</div>
              </div>

              <div className="register">
                <form className='text-center' onSubmit={handleSubmit} noValidate>
                  {showOverlay && <div className='tooltip-content'>
                    <p>Please enter a valid email address, note that transaction updates will be sent to this email.</p>
                    <span className='caret down' />
                  </div>}
                  <input autoFocus placeholder="Email Address" type="email" className='email position-relative bg-transparent' value={email} onChange={(event) => {
                    setEmail(event.target.value);
                    if (error.length) {
                      setError('');
                    }
                  }} />
                  {error && <div className='validate'>{error}</div>}
                  <div className='d-flex justify-content-center'>
                    <button className="shap-shap-btn-black d-block d-sm-block d-md-none">
                      {loading && <span className="spinner"><Triangle height="30" width="30" color='white' ariaLabel='loading' className="spinner" /></span>}
                      {!loading && 'Start Trade'}
                     </button>

                    <button className="shap-shap-btn-trade d-none d-md-block d-lg-block d-xl-block">
                      {loading && <span className='spinner'><Triangle height="30" width="30" color='white' ariaLabel='loading' className="spinner" /></span>} 
                      {!loading && 'Start Trade'}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Trade