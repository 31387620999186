import React from 'react';
import PlayStoreMain from '../assets/images/playstore-main.svg';
import GooglePlay from '../assets/images/play-store.svg';
import AppleStore from '../assets/images/apple-store.svg';
import { Link } from 'react-router-dom';


function Playstore() {
  return (
    <div id="playstore" className='bg-light playstore' >
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-6 d-flex justify-content-center justify-content-sm-center justify-content-lg-end ">
            <img src={PlayStoreMain} alt="playstore" className='playstore playstore-img'/>
          </div>
          <div className="col-12 col-lg-6">
            <div className="download-container">
              <div className="download-header">
                Download the Shapshap App
              </div>
            </div>
            <div className="download-text">
            You can even make it SHARPER than SHARP, 
when you use the <span>SHAPSHAP</span> app.

            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 my-5 d-flex justify-content-center justify-content-md-center justify-content-lg-start">
                <a href="https://play.google.com/store/apps/details?id=com.shap_shap" target="_blank">
                  <button className="playstoreGreen me-2"><img src={GooglePlay} alt="Google Play" /></button>
                </a>

                <a href="https://apps.apple.com/us/app/sell-gift-cards-shapshap/id1632968146" target="_blank">
                  <button className='playstoreWhite ms-2'><img src={AppleStore} alt="Apple Store" /></button>
                </a>
              </div>
            </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  )
}

export default Playstore