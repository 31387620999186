import React, { useState } from 'react';
import TradeHeader from './TradeHeader';
import TradeStatusImg from '../assets/images/trade-status.svg';
import { useNavigate } from 'react-router-dom';



function TradeStatus() {
  const [id, setId] = useState('');
  const navigate = useNavigate();

  const handleSubmit = (evt) => {
    evt.preventDefault();
    navigate('/trade-status-details', {state: {ref: id}});
  }

  return (
    <div className='animate__animated animate__zoomIn animate__faster'>
      <TradeHeader />
      <div id="trade-status">

        <div className="container">
          <div className="row">
            <div className="col-10 col-sm-10 col-md-6 m-auto">
              <div className="trade-status-header">Trade Status</div>

              <div className='d-flex justify-content-center'>
                <img className="animate__animated animate__pulse animate__slow animate__infinite" src={TradeStatusImg} alt="trade status" />
              </div>

              <div className="trade-status-text">
              All we need is your Transaction ID so<br/> we can <span className='text-green'>HUNT</span> down the trade.
              </div>

              <div className="trade-status-form">
                <form className='text-center' onSubmit={handleSubmit}>
                  <input placeholder="Email Transaction ID" type="text" className='email' value={id} onChange={(e) => setId(e.target.value)} />
                  <div className='d-flex justify-content-center'>
                    <button className="shap-shap-btn-black d-block d-sm-block d-md-none">Check Trade Status</button>
                    <button className="shap-shap-btn-trade d-none d-md-block d-lg-block d-xl-block">Check Trade Status</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </div>
  )
}

export default TradeStatus