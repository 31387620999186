import React, { useState } from 'react';
import CloseBtn from '../assets/images/menu-close.svg';
import CloseBtnMobile from '../assets/images/close-btn-mobile.svg';
import TradeSuccess from '../assets/images/trade-success.svg';
import { useNavigate } from 'react-router-dom';

function Success() {
  const [id, setId] = useState('');
  const navigate = useNavigate();

  const getTradeStatus = () => {
    navigate('/trade-status-details', {state: {ref: id}});
  }

  return (
    <>
      <section id='success'>
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-10 d-flex justify-content-end">
              <img className="close-trade-terms d-none d-md-block d-lg-block" src={CloseBtn} alt="close button" />
            <img className="close-trade-terms d-block d-sm-block d-md-none d-lg-none" src={CloseBtnMobile} alt="close button" />
            </div>
          </div>
          <div className="row">
            <div className="col-10 col-sm-10 col-md-6 m-auto">
              <div className="trade-success-img text-center">
                <img className='animate__animated animate__pulse animate__infinite' src={TradeSuccess} alt="trade-sucess" />
              </div>

              <div className="that-was-sharp">
              That was <span className="text-green">Sharp!</span>   
              </div>

              <div className="success-feedback-text">
              You will get a feedback via email within <br /> a few minutes.
              </div>

              <div className="alternative-text">
              Alternatively, you can click the button below <br/> to check your live trade status.
              </div>

              <div className="go-to-status d-flex justify-content-center">
                <button onClick={getTradeStatus} className="shap-shap-btn-black d-block d-sm-block d-md-none">Check Trade Status</button>
                <button onClick={getTradeStatus} className="shap-shap-btn-trade d-none d-md-block d-lg-block d-xl-block">Check Trade Status</button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Success