import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import Trade from '../src/pages/Trade';
import Trading from '../src/pages/Trading';
import Confirmation from './pages/Confirmation';
import TradeTerms from './pages/TradeTerms';
import Success from './pages/Success';
import TradeStatus from './pages/TradeStatus';
import RejectionReason from './pages/RejectionReason';
import TradeStatusDetails from './pages/TradeStatusDetails';
import TradeStatusPending from './pages/TradeStatusPending';
import TradeStatusRejected from './pages/TradeStatusRejected';
import EmailTooltip from './tooltips/EmailTooltip';
import CardCategoryTooltip from './tooltips/CardCategoryTooltip';
import GiftCardAmount from './tooltips/GiftCardAmount';
import PaymentTypeTooltip from './tooltips/PaymentTypeTooltip';
import ImageTooltip from './tooltips/ImageTooltip';
import CardTypeTooltip from './tooltips/CardTypeTooltip';
import BankDetailsTooltip from './tooltips/BankDetailsTooltip';
import Overlay from './pages/Overlay';
import NotFound from './pages/NotFound';

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route exact path="/" element={<App />} />
        <Route exact path="trade" element={<Trade />} />
        <Route exact path="trading" element={<Trading />} />
        <Route exact path="terms" element={<TradeTerms />} />
        <Route exact path="confirmation" element={ <Confirmation/>} />
        <Route exact path="success" element={ <Success/>} />
        <Route exact path="trade-status" element={ <TradeStatus/>} />
        <Route exact path="rejection-reason" element={ <RejectionReason/>} />
        <Route exact path="trade-status-details" element={ <TradeStatusDetails/>} />
        <Route exact path="bank-details" element={ <BankDetailsTooltip/>} />
        <Route exact path="email-tooltip" element={ <EmailTooltip/>} />
        <Route exact path="overlay" element={<Overlay/>} />
        <Route exact path="*" element={<NotFound/>} />
      </Routes>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
