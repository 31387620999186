import React, { useEffect } from 'react';
import CloseBtn from '../assets/images/menu-close.svg';
import CloseBtnMobile from '../assets/images/close-btn-mobile.svg';
import Rejected from '../assets/images/rejected.svg';
import RejectedIcon from '../assets/images/rejected-icon.svg';
import { useLocation, useNavigate } from 'react-router-dom';

function RejectionReason() {
  const {state} = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!state?.rejectionReason) {
      navigate('/trade-status');
    }
  }, [state]);
  return (
    <>
      <section id='rejection'>
        <div className="container">
          <div className="row">
            <div className="col-11 col-sm-11 col-md-11 d-flex justify-content-end">
              <img className="close-trade-terms d-none d-md-block d-lg-block" src={CloseBtn} alt="close button" />
              <img className="close-trade-terms d-block d-sm-block d-md-none d-lg-none" src={CloseBtnMobile} alt="close button" />
            </div>
          </div>
          <div className="row">
            <div className="col-10 col-sm-10 col-md-6 m-auto">
              <div className='rejection-header'>
                Rejection Reason
              </div>

              <div className="rejection-text">
                {state?.rejectionReason}
              </div>

              <div className="rejection-images rejection-text">
                Rejection images
              </div>
            </div>
          </div>
          <div className="rejected-text pt-3">Rejected</div>
          <div className="d-flex justify-content-center pt-1">
            <img src={Rejected} alt="rejected" />
            <img src={RejectedIcon} alt="rejected-icon" />
          </div>
        </div>
      </section>
    </>
  )
}

export default RejectionReason