import React from 'react'

function FAQ() {
  return (
    <section id="FAQ">
      <div className="container">
        <div className="row d-flex justify-content-center">
          <div className="col-lg-7 hide-faq d-none d-md-block d-lg-block">
            <div className="faq-header">
            Frequently Asked Questions
            </div>
          </div>

          <div className="col-lg-7 show-faq d-block d-sm-block d-md-none">
            <div className="faq-header">
            FAQS
            </div>
          </div>

          <div className="col-lg-10">
            <div className="accordion" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                  <button className="accordion-button faq-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                  How long does trading take?
                  </button>
                </h2>
                <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                  <div className="accordion-body">
                  An average gift card trade time on Shapshap is 5 minutes.
                  
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingTwo">
                  <button className="accordion-button collapsed faq-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                  How soon do I get paid?
                  </button>
                </h2>
                <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                  <div className="accordion-body">
                  Payments are sent to the provided payment details immediately after the transaction is completed. You can be paid in Naira, Cedis, BT, or USD.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingThree">
                  <button className="accordion-button collapsed faq-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                  When can I trade?
                  </button>
                </h2>
                <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                  <div className="accordion-body">
                  We operate 24/7 so you can trade anytime you want. We also have a live chat available on the platform, so whenever you need any form of help, don’t hesitate to ask.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default FAQ