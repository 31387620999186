import React, { useState, useEffect } from 'react';
import LottiePlayer  from 'lottie-react';
import TradeHeader from '../pages/TradeHeader';
import Bitcoin from '../assets/images/btcoin.svg';
import BitcoinInactive from '../assets/images/bitcoin-inactive.svg';
import Tether from '../assets/images/usd.svg';
import TetherInactive from '../assets/images/tether-inactive.svg';
import Cedis from '../assets/images/cedis.svg';
import CedisInactive from '../assets/images/cedis-inactive.svg';
import Naira from '../assets/images/naira.svg';
import NairaInactive from '../assets/images/naira-inactive.svg';
import SubmitButton from '../assets/images/submit.svg';
import Axios from '../utils/axios';
import SubmitButtonTooltip from '../assets/images/submit.svg';
import animationData from '../lotties/upload.json';


function BankDetailsTooltip() {
  const [active, setActive] = useState('bitcoin');

  useEffect(() => {
    Axios.get('nigerian-banks')
      .then(response => {
        console.log('status', response.status)
        console.log('data', response.data)
      })
      .catch(err => console.error(err));
  }, []);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  return (
    <>
    <div className='tooltip-overlay'></div>
    <TradeHeader/>
    <section id="trading" className='animate__animated animate__bounceIn'>
      <div className="container">
        <div className="row">
          <div className='col-10 col-sm-10 col-md-6 col-lg-6 m-auto'>
            <select className="select mb-3 text-blue">
              <option defaultValue>Gift Card Category</option>
              <option value="1">One</option>
              <option value="2">Two</option>
              <option value="3">Three</option>
            </select>

            <select className="select mb-3 text-blue">
              <option defaultValue>Gift Card Type</option>
              <option value="1">One</option>
              <option value="2">Two</option>
              <option value="3">Three</option>
            </select>

            <div className='amount text-center'>
              <p className='amount-text'>Gift Card Amount</p>
              <input className='amount-form' type="number" placeholder='0.00' />
            </div>

            <div className="payments">
              <p className='payments-text text-blue'>How do you want payment?</p>

              <div className="container my-4 payment-options">
                <div className="d-flex justify-content-around">
                  <div className="col-3 col-md-3" onClick={() => setActive('bitcoin')}>
                    <img src={active === 'bitcoin' ? Bitcoin : BitcoinInactive} alt="bitcoin" />
                  </div>
                  <div className="col-3 col-md-3" onClick={() => setActive('tether')}>
                    <img src={active === 'tether' ? Tether : TetherInactive} alt="tether" />
                  </div>
                  <div className="col-3 col-md-3" onClick={() => setActive('cedis')}>
                    <img src={active === 'cedis' ? Cedis : CedisInactive} alt="cedis" />
                  </div>
                  <div className="col-3 col-md-3" onClick={() => setActive('naira')}>
                    <img src={active === 'naira' ? Naira : NairaInactive} alt="naira" />
                  </div>
                </div>
              </div>

              
              {
                active === 'bitcoin' && <div>
                  <div className='coin-type text-center'><p className='animate__animated animate__zoomIn animate__faster'> Bitcoin - BTC </p></div>
                  <form action="">
                    <input className='btc-address-form' type="number" placeholder='BTC Address' />
                    <p className="validate">Please, Enter the Correct BTC Address!</p>
                    <input className="shap-shap-btn shap-shap-btn-black" type="submit" value="Save Address" />

                    <div className="total-amount text-blue d-flex justify-content-between container">
                      <p>You will be PAID</p>
                      <p>0.0327 BTC</p>
                    </div>
                    <div className="upload-image">
                      <input type="file" id="defult-btn" hidden/>
                      <div className="upload-image bg-light d-flex justify-content-center align-item-center">
                        <LottiePlayer className='lottie' {...defaultOptions}/>
                      </div>
                      <button className='upload-image-text' id="custom-btn" type='button'>Upload Images</button>
                    </div>

                    <div className="e-code">
                      <p className="e-code-text">Enter Code (Only If ECODE)</p>
                      <input className='e-code-form' type="number" placeholder='E-code' />
                      <div className="submit d-flex justify-content-end align-items-center">
                        <div className='mx-3 submit-text'>Submit</div>
                        <button className='submit-btn' type='submit'>
                          <img src={SubmitButton} alt="submit" />
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              }

              {
                active === 'tether' && <div>
                <div className='coin-type text-center'><p className='animate__animated animate__zoomIn animate__faster'> Tether - USDT </p></div>
                  <form action="">
                    <input className='btc-address-form' type="number" placeholder='USDT Address' />
                    <p className="validate">Please, Enter the Correct USDT Address!</p>
                    <input className="shap-shap-btn shap-shap-btn-black" type="submit" value="Save Address" />

                    <div className="total-amount text-blue d-flex justify-content-between container">
                      <p>You will be PAID</p>
                      <p>100 USDT</p>
                    </div>
                    <div className="upload-image">
                      <input type="file" id="defult-btn" hidden/>
                      <div className="upload-image bg-light d-flex justify-content-center align-item-center">
                        <LottiePlayer className='lottie' {...defaultOptions}/>
                      </div>
                      <button className='upload-image-text' id="custom-btn" type='button'>Upload Images</button>
                    </div>

                    <div className="e-code">
                      <p className="e-code-text">Enter Code (Only If ECODE)</p>
                      <input className='e-code-form' type="number" placeholder='E-code' />
                      <div className="submit d-flex justify-content-end align-items-center">
                        <div className='mx-3 submit-text'>Submit</div>
                        <button className='submit-btn' type='submit'>
                          <img src={SubmitButton} alt="submit" />
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              }

              {
                active === 'cedis' && <div>
                <div className='coin-type text-center'><p className='animate__animated animate__zoomIn animate__faster'>Cedis - GHS</p></div>
                  <form action="">
                    <input className='btc-address-form' type="text" placeholder='Enter Bank Name' />
                    <input className='btc-address-form' type="number" placeholder='Account Number' />
                    <input className='btc-address-form' type="text" placeholder='Enter Full Name' />
                    <input className="shap-shap-btn shap-shap-btn-black" type="submit" value="Save Details" />

                    <div className="total-amount text-blue d-flex justify-content-between container">
                      <p>You will be PAID</p>
                      <p>500,000 GHS</p>
                    </div>
                    <div className="upload-image">
                      <input type="file" id="defult-btn" hidden/>
                      <div className="upload-image bg-light d-flex justify-content-center align-item-center">
                        <LottiePlayer className='lottie' {...defaultOptions}/>
                      </div>
                      <button className='upload-image-text' id="custom-btn" type='button'>Upload Images</button>
                    </div>

                    <div className="e-code">
                      <p className="e-code-text">Enter Code (Only If ECODE)</p>
                      <input className='e-code-form' type="number" placeholder='E-code' />
                      <div className="submit d-flex justify-content-end align-items-center">
                        <div className='mx-3 submit-text'>Submit</div>
                        <button className='submit-btn' type='submit'>
                          <img src={SubmitButton} alt="submit" />
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              }

              {
                active === 'naira' && <div className="">
                <div className='coin-type text-center'><p className='animate__animated animate__zoomIn animate__faster'> Naira - NGN </p>
                </div>
                  <form action="">
                    <select className="select my-3 text-blue">
                      <option defaultValue>Select bank</option>
                      <option value="1">One</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </select>
                    

                    <input className='btc-address-form' type="number" placeholder='Account Number' />

                    <input className='btc-address-form' type="text" placeholder='Account Name' />

                    <input className="shap-shap-btn shap-shap-btn-black" type="submit" value="Save Details" />

                    <div className="total-amount text-blue d-flex justify-content-between container">
                      <p>You will be PAID</p>
                      <p>500,000 NGN</p>
                    </div>
                    <div className="upload-image">
                      <input type="file" id="defult-btn" hidden/>
                      <div className="upload-image bg-light d-flex justify-content-center align-item-center">
                        <LottiePlayer className='lottie' {...defaultOptions}/>
                      </div>
                      <button className='upload-image-text' id="custom-btn" type='button'>Upload Images</button>
                    </div>

                    <div className="e-code">
                      <p className="e-code-text">Enter Code (Only If ECODE)</p>
                      <input className='e-code-form' type="number" placeholder='E-code' />
                      <div className="submit d-flex justify-content-end align-items-center">
                        <div className='mx-3 submit-text'>Submit</div>
                        <button className='submit-btn' type='submit'>
                          <img src={SubmitButton} alt="submit" />
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              }

            </div>
          </div>
        </div>
      </div>
    </section>
  </>
  )
}

export default BankDetailsTooltip