import React from 'react';
import { Link } from 'react-router-dom';
import Logo from '../assets/images/logo.svg';

function Header() {
  return (
  <nav className="navbar navbar-expand-lg header bg-light">
    <div className="container">
      <Link className="navbar-brand navbar-brand-mobile" to="/">
        <img src={Logo} className="logo-2 main-logo" alt="logo" />
      </Link>
      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
          <li className="nav-item top-margin">
            <a className="nav-link active" aria-current="page" href="https://api.whatsapp.com/send?phone=2348148444013&text=Hello" target="_blank">Whatsapp</a>
          </li>
          <li className="nav-item border-green">
            <a className="nav-link active " href="https://onelink.to/6v5mn7" target="_blank">Get the App</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  )
}

export default Header