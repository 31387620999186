import React from 'react';
import 'animate.css';
import Dollar_bag from '../assets/images/dollar-bag.svg';
import Bitcoin from '../assets/images/btcoin.svg';
import Tether from '../assets/images/usd.svg';
import Naira from '../assets/images/naira.svg';
import Cedis from '../assets/images/cedis.svg';
import Arrow from '../assets/images/arrow.svg';
import Quick from '../assets/images/quick.svg';
import Convenient from '../assets/images/convenient.svg';
import Safe from '../assets/images/safe.svg';
import Phone from '../assets/images/phone.png';
import PhoneMobile from '../assets/images/phone-mobile.png';
import Email from '../assets/images/email.svg';
import Information from '../assets/images/information.svg';
import Minutes from '../assets/images/minutes.svg';
import Talk from '../assets/images/talk.svg';
import { Link } from 'react-router-dom';
import Steam from '../assets/images/steam-anime.svg';
import PlayStation from '../assets/images/playstation-anime.svg';
import Amazon from '../assets/images/amazon-anime.svg';
import Apple from '../assets/images/apple-anime.svg';
import Xbox from '../assets/images/xbox-anime.svg';
import BestBuy from '../assets/images/bestbuy-anime.svg';
import Ebay from '../assets/images/ebay-anime.svg';


function Main() {

  return (
    <div className='bg-light main'>
      <img src={Steam} alt="steam" className='steam animate__animated animate__bounce animate__delay-2s animate__slower animate__infinite' />


      <h2 className='text-center shap-shap-header'>Sell Gift Cards ShapShap!</h2>

      <p className="shap-shap-text mb-5 px-3 px-md-0">We skip registration stress and <br/>
get straight to business. Use SHAPSHAP to instantly <br/>
sell gift cards and be paid in less than 3 minutes.</p>

      <div className="container position-relative">
        <img src={Amazon} alt="amazon" className='amazon animate__animated animate__swing animate__delay-2s animate__slower animate__infinite' />
        <img src={Xbox} alt="xbox" className='xbox animate__animated animate__flash animate__delay-2s animate__slower animate__infinite' />
        <div className="row">
          <div className="col-11 col-md-8 col-lg-6 shap-shap-trade m-auto">
            <div className="row">
              <div className='bg-light trade-text col-lg-10 m-auto mb-3'>Sell Gift Card and be Paid in<img src={Dollar_bag} className="dollar-bag" alt="dollar-bag" /> </div>

              <div className="col-md-12 col-lg-11 m-auto">
                <div className="row d-flex justify-content-around">
                  <div className="col-5 col-sm-5 col-md-5 col-lg-5 my-2 bitcoin d-flex justify-content-center justify-content-md-between justify-content-lg-between"><img src={Bitcoin} alt="BTCOIN" /> <div className='d-flex align-items-center me-2 coin-text'>BT</div></div>

                  <div className="col-5 col-sm-5 col-md-5 col-lg-5 my-2 tether d-flex justify-content-center justify-content-md-between justify-content-lg-between"> <img src={Tether} alt="USD" /> <div className="d-flex align-items-center me-2 coin-text">USD</div> </div>

                  <div className="col-5 col-sm-5 col-md-5 col-lg-5 my-2 naira d-flex justify-content-center justify-content-md-between justify-content-lg-between"><img src={Naira} alt="naira" /> <div className="d-flex align-items-center me-2 coin-text">Naira</div> </div>

                  <div className="col-5 col-sm-5 col-md-5 col-lg-5 my-2 cedis d-flex justify-content-center justify-content-md-between justify-content-lg-between"><img src={Cedis} alt="cedis" /> <div className="d-flex align-items-center me-2 coin-text">Cedis</div> </div>
                </div>
              </div>          
            </div>
          
          </div>
        </div>
        <img src={PlayStation} alt="playstation" className='playstation animate__animated animate__wobble animate__delay-2s animate__slower animate__infinite' />
        <img src={Apple} alt="apple" className='apple animate__animated animate__heartBeat animate__delay-2s animate__slower animate__infinite' />
      </div>

      <div className="container position-relative">
        <img src={BestBuy} alt="bestbuy" className='bestbuy animate__animated animate__tada animate__delay-2s animate__slower animate__infinite' />
        <img src={Ebay} alt="ebay" className='ebay animate__animated animate__jello animate__delay-2s animate__slower animate__infinite' />
        <div className="row">
          <div className="col-md-4 m-auto d-flex justify-content-center">
            <img src={Arrow} alt="Arrow"  className='shap-shap-arrow my-4'/>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-4 m-auto">
            <div className="row">
              <div className="col-12 col-md-6 my-1 d-flex justify-content-center justify-content-md-end">
                <a href="https://play.google.com/store/apps/details?id=com.shap_shap" target="_blank"><button className='shap-shap-btn trade-btn'>Android App</button></a>
              </div>

              <div className="col-12 col-md-6 my-1 d-flex justify-content-center justify-content-md-start">
                <a href="https://apps.apple.com/us/app/sell-gift-cards-shapshap/id1632968146" target="_blank">
                  <button className="shap-shap-btn status-btn text-green">iOS App</button>
                </a>
              </div>

            </div>
          </div>
        </div>
      </div>

      <section id="features">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 m-auto my-4">
              <img src={Quick} alt="quick"/>
              <h3 className="feature-heading">It’s Quick</h3>
              <p className="feature-text">It’s called shapshap for a reason. <br/>
It’s fast and effective.</p>
            </div>

            <div className="col-lg-4 m-auto my-4">
              <img src={Convenient} alt="convenient"/>
              <h3 className="feature-heading">It’s Convenient</h3>
              <p className="feature-text">Built with a convenience-first model, <br/>
don’t stress it.</p>
            </div>
            <div className="col-lg-4 m-auto my-4">
              <img src={Safe} alt="safe"/>
              <h3 className="feature-heading">It’s Safe</h3>
              <p className="feature-text">Secured transactions and processes <br/>
are guaranteed.</p>
            </div>
          </div>
        </div>
      </section>

      <section className="how-it-works">
        <div className="container">
          <div className="row m-auto">
            <div className="col-12 col-sm-12 col-md-12 col-lg-6 d-flex justify-content-center justify-content-md-center justify-content-lg-end">
              <img src={PhoneMobile}  className="phone d-block d-sm-block d-md-none" alt="phone-mobile" />
              <img src={Phone}  className="phone d-none d-sm-none d-md-block d-lg-block" alt="phone" />
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-6">
              <div className="how-it-works-text text-sm-center d-none d-md-block d-lg-block">
                  How it Works
              </div>

              <div className="workings">
                <div className="emails d-lg-flex">
                  <img src={Email} alt="email" />
                  <div className='dist'>
                    <h4 className='workings-header'>Provide Email</h4>
                    <p className='workings-text'>Enter your email address to initiate a trade. 
Emails are automatically validated in the background to avoid 
bots and spam. Transaction updates are sent to your email, 
so do make sure the email is correct. </p>
                  </div>
                </div>
                <div className="information d-lg-flex">
                  <img src={Information} alt="information" />
                  <div className='dist'>
                    <h4 className='workings-header'>Enter Trade Information</h4>
                    <p className='workings-text'>Submit the trade details as required and wait for a sharp update. </p>
                  </div>
                </div>
                <div className="minutes d-lg-flex">
                  <img src={Minutes} alt="minutes" />
                  <div className='dist'>
                    <h4 className='workings-header'>Wait Few Minutes</h4>
                    <p className='workings-text'>Transactions are mostly completed within 5 minutes and
you should receive your payment instantly. </p>
                  </div>
                </div>
                <div className="talk d-lg-flex">
                  <img src={Talk} alt="talk" />
                  <div className='dist'>
                    <h4 className='workings-header'>Talk To Us Anytime</h4>
                    <p className='workings-text'>If you have any questions, contact the support team. You can use the live chat, or Whatsapp.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    </div>
    
  )
}

export default Main