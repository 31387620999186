import axios from 'axios';
import {BASE_URL, API_KEY, TIME_STAMP, HASH} from './api';

const Axios = axios.create({
  baseURL: BASE_URL,
});

Axios.defaults.headers['Content-Type'] = 'application/json';
Axios.defaults.headers['Accept'] = 'application/json';
Axios.defaults.headers['X-SHAP-API-KEY'] = API_KEY;
Axios.defaults.headers['X-SHAP-REQUEST-HASH'] = HASH;
Axios.defaults.headers['X-SHAP-REQUEST-TIMESTAMP'] = TIME_STAMP;

export default Axios;
