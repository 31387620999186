import React from 'react';
import LottiePlayer from 'lottie-react';
import animationDataTwo from '../lotties/error.json';
import ErrorHeader from './ErrorHeader';


function NotFound() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationDataTwo,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };
  return (
    <>
      <ErrorHeader />
      <section className="not-found d-flex justify-content-center align-items-center">
        <div className="d-block d-sm-block d-md-none">
          <LottiePlayer className='error-lottie-mobile' {...defaultOptions}/>
        </div>
        <div className="d-none d-md-block d-lg-block d-xl-block">
          <LottiePlayer className='error-lottie' {...defaultOptions}/>
        </div>
      </section>
    </>
  )
}

export default NotFound