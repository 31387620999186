import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Logo from '../assets/images/logo.svg';
import MenuOpen from '../assets/images/menu-open.svg';
import MenuClose from '../assets/images/menu-close.svg';

function TradeHeader() {
  const [isOpen, setIsOpen] = useState(false);
  
  const liveChat = () => {
    console.log('clicked')
    const chatBtn = document.querySelector('.intercom-launcher');
    if (chatBtn && chatBtn.click) {
      chatBtn.click();
      console.log('clicked')
    }
  };

  return (
    <nav className="navbar navbar-expand-lg header">
      <div className="container">
        <Link className="navbar-brand navbar-brand-trade" to="/"><img src={Logo} className="logo" alt="logo" /></Link>
          <button onClick={() => setIsOpen(!isOpen)} className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <img src={isOpen ? MenuClose : MenuOpen} alt="menu-open"/>
          </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
            <li className="nav-item top-margin">
              <div className="pointer nav-link active hover" aria-current="page" onClick={liveChat}>LiveChat</div>
            </li> 

            <li className="nav-item top-margin">
              <Link className="nav-link active hover" to="/trade-status">Check Status</Link>
            </li>
             
            <li className="nav-item border-green">
              <Link className="nav-link active hover" to="/trading">Trade</Link>
            </li> 
          </ul>
        </div>
      </div>
    </nav>
  )
}

export default TradeHeader