import React from 'react';
import CloseBtn from '../assets/images/menu-close.svg';
import CloseBtnMobile from '../assets/images/close-btn-mobile.svg';

function TermsModal({ Terms }) {
  return (
    <section id='trade-terms' className='animate__animated animate__zoomIn animate__faster'>
      <div className="container">
        <div className="row">
          <div className="col-12 col-sm-12 col-md-10 d-flex justify-content-end">
            <img onClick={ Terms } className="close-trade-terms d-none d-md-block d-lg-block" src={CloseBtn} alt="close button" />
            <img onClick={ Terms } className="close-trade-terms d-block d-sm-block d-md-none d-lg-none" src={CloseBtnMobile} alt="close button" />
          </div>
        </div>
        <div className="row">
          <div className="col-10 col-sm-10 col-md-6 m-auto">
            <div className='terms-header'>
              Trade Terms
            </div>

            <div className="trade-terms-text">
              <h6 className='text-center'>Introduction:</h6>
              This Privacy Policy applies to all of the products, services, and websites offered by Shapshap or its subsidiaries or affiliated companies. In addition, where more detailed information is needed to explain our privacy practices, we post supplementary privacy notices to describe how particular services process personal information. We understand that in the course of delivering our numerous products/ services to you via the net, we will come across certain information pertaining to your good self which, to our mind, are purely personal and may even be classified information but for the existing relationship which dictates that you shall either fill them in and/or divulge same in order to be linked to our site. Shapshap will handle the information in this manner:
            </div>

            <div className="trade-terms-text">
              <h6 className="text-center"> Use Of Information:</h6>
              When you sign up for any Shapshap Service or promotion that requires registration, we ask you for personal information (such as your name, email address and an account password). For certain services, such as our payment services, we require your debit or credit card information. We typically do not store this information and when we do, we maintain the data in encrypted form on secure servers. We may combine the information you submit under your account with information from other third parties in order to provide you with a better experience and to improve the quality of our services. For certain services, we may give you the opportunity to opt out of combining such information.
              
              When you access Shapshap, our servers automatically record information that your browser sends whenever you visit a website. When you send email or other communications to Shapshap, we may retain those communications in order to process your inquiries, respond to your requests and improve our services.
              
              We offer some of our services in connection with other web sites e.g. Websites of our subsidiaries. Personal information that you provide to those sites may be sent to Shapshap in order to deliver the service. We process such information in accordance with this Privacy Policy. The affiliated sites may have different privacy practices and we encourage you to read their privacy policies.
              
              This Privacy Policy applies to Shapshap only. We do not exercise control over the sites displayed or linked from within our various services. These other sites may place their own cookies or other files on your computer, collect data or solicit personal information from you.

            </div>

            <div className="trade-terms-text">
              <h6 className="text-center">Data Policy:</h6>
              Shapshap Data Protection Policy refers to our commitment to treat information of employees, customers, stakeholders and other interested parties with the utmost care and confidentiality. With this policy, we ensure that we gather, store and handle data fairly, transparently and with respect towards individual rights.
            </div>

            <div className="trade-terms-text">
              <h6 className="text-center">Scope:</h6>
              This policy refers to all parties (employees, customers, suppliers, Merchants etc.) who provide any amount of information to us. Who is covered under the Data Protection Policy? Employees of our company and its subsidiaries must follow this policy. Contractors, consultants, partners and any other external entity are also covered. Generally, our policy refers to anyone we collaborate with or acts on our behalf and may need occasional access to data.
            </div>

            <div className="trade-terms-text">
              <h6 className="text-center">Policy Elements:</h6>
              As part of our operations, we need to obtain and process information. This information includes any offline or online data that makes a person identifiable such as names, addresses, usernames and passwords, digital footprints, photographs, social security numbers, financial data etc. Our company collects this information in a transparent way and only with the full cooperation and knowledge of interested parties.
            </div> 

            <div className="trade-terms-text">
            <h6 className="text-center">Anti-Money Laundering Policy:</h6>
            This policy refers to all parties (employees, customers, suppliers, Merchants etc.) who provide any amount of information to us. Who is covered under the Data Protection Policy? Employees of our Money laundering is the attempt to conceal or disguise the nature, location, source, ownership or control of illegally obtained money. Money laundering is most commonly associated with tax avoidance. However, other individuals may attempt to launder money in order to conceal their identity or finance their operations. ‘Suspicious activity’ is a very difficult concept to define because it can vary from one transaction to another based upon all the circumstances surrounding the transaction or group of transactions. For example, transactions by one customer may be normal based on our knowledge of that customer and their pattern of activity, while similar transactions by another customer may be suspicious.

            </div>  
          </div>
        </div>
      </div>
    </section>
  )
}

export default TermsModal