import React from 'react';
import { Link } from 'react-router-dom';
import Logo from '../assets/images/logo.svg';

function Footer() {
  return (
    <footer id="footer" className='footer text-center'>
      <div className="container">
        <div className="row">
          <div className="col-12 col-sm-12 col-md-12 col-lg-3">
            <img src={Logo} className="footer-logo" alt="footer logo" />
          </div>
          <div className="col-12 col-sm-12 col-md-12 col-lg-6 footer-links">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                <p>+2348148444013
                </p>
              </div>

              <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                Sup@shapshap.cards
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-12 col-lg-3">
            <a href="https://api.whatsapp.com/send?phone=2348148444013&text=Hello" className='whatsapp'>WHATSAPP</a>
            <a href="https://www.instagram.com/shapshap.cards/" className='email-footer'>INSTAGRAM</a>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer