import React, { useEffect, useState } from 'react';
import Paid from '../assets/images/paid.svg';
import PaidIcon from '../assets/images/paid-icon.svg';
import Pend from '../assets/images/pending.svg';
import PendingIcon from '../assets/images/pending-icon.svg';
import Rejected from '../assets/images/rejected.svg';
import RejectedIcon from '../assets/images/rejected-icon.svg';
import Naira from '../assets/images/naira.svg';
import Bitcoin from '../assets/images/btcoin.svg';
import Tether from '../assets/images/usd.svg';
import Cedis from '../assets/images/cedis.svg';
import CloseBtn from '../assets/images/menu-close.svg';
import CloseBtnMobile from '../assets/images/close-btn-mobile.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import { Triangle } from 'react-loader-spinner';
import Axios from '../utils/axios';

function TradeStatusDetails() {
  const {state} = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [trade, setTrade] = useState();

  useEffect(() => {
    if (!state?.ref) {
      navigate('/trade-status');
      return;
    }

    Axios.get(`/trades/${state?.ref}`)
    .then(res =>{
      console.log('res is:', res);
      setTrade(res.data);
    })
    .catch(err => console.log('err', err))
    .finally(() => setLoading(false));
    
  }, [state]);

  console.log('state', state?.ref);
  const renderIcon = () => {
    switch (trade.payoutMethod) {
      case "CEDIS":
        return Cedis;
      case "BITCOINS":
        return Bitcoin;
      case "USDT":
        return Tether;
      case "NAIRA":
      default:
        return Naira;
    }
  }

  return (
    <>
      <section id='trade-status-paid'>
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-10 d-flex justify-content-end my-1 pointer" onClick={() => navigate(-1)}>
              <img className="close-trade-terms d-none d-md-block d-lg-block" src={CloseBtn} alt="close button" />
              <img className="close-trade-terms d-block d-sm-block d-md-none d-lg-none" src={CloseBtnMobile} alt="close button" />
            </div>
          </div>
          {loading && <span className="spinner"><Triangle height="100" width="100" color='black' ariaLabel='loading' className="spinner" /></span>}
          {!loading && !trade && <div className="confirm-header">Trade Not Found</div>}
          {!loading && trade && (
            <div className="row">
              <div className="col-10 col-sm-10 col-md-6 m-auto">
                <div className="confirm-header">Trade Status</div>

                <div className="confirm-details">
                  <div className="category d-flex justify-content-between">
                    <span className="card-category">Gift card category</span>
                    <span className="value">{trade.giftCardCategory.name}</span>
                  </div>
                </div>

                <div className="confirm-details">
                  <div className="category d-flex justify-content-between">
                    <span className="card-category">Gift card type</span>
                    <span className="value">{trade.giftCard.name}</span>
                  </div>
                </div>

                <div className="confirm-details">
                  <div className="category d-flex justify-content-between">
                    <span className="card-category">Gift card Amount</span>
                    <span className="value text-green">{trade.units}</span>
                  </div>
                </div>

                <div className="confirm-details">
                  <div className="category d-flex justify-content-between align-items-center">
                  <span className="card-category">Payment method <br /> <span className='value'>{state?.payoutMethod}</span></span>
                  <span className="value">
                    <img src={renderIcon()} alt="" style={{height: '45px', width: '45px'}} />
                  </span>
                  </div>
                </div>

                {
                  trade.payoutMethod === "NAIRA" && (
                    <div className="confirm-details">
                      <div className="category d-flex justify-content-between align-items-center">
                        <span className="bank-details">Bank - {trade.payoutBankName} <br/>
                        A/C Number - {trade.payoutAccountNumber} <br />
                        Name - {trade.payoutAccountName}
                        </span>
                      </div>
                    </div>
                  )
                }

                {
                  trade.payoutMethod === "CEDIS" && (
                    <div className="confirm-details">
                      <div className="category d-flex justify-content-between align-items-center">
                        <span className="bank-details">Bank - {trade.payoutBankName} <br/>
                        A/C Number - {trade.payoutAccountNumber} <br />
                        Name - {trade.payoutAccountName}
                        </span>
                      </div>
                    </div>
                  )
                }

                <div className="confirm-details">
                  <div className="category d-flex justify-content-between">
                    <span className="card-category">You will be PAID <br /> <span className='value'>Rate - 100</span></span>
                    <span className="final-value text-green">{trade.payoutTotal}</span>
                  </div>
                </div>

                
                <div className="confirm-details">
                  <div className="category d-flex justify-content-between">
                    <span className="card-category">Comments:</span>
                    <span className="text-blue">{trade.ecode}</span>
                  </div>
                </div>

                {trade.status.toLowerCase() === 'rejected' && (
                  <div className="confirm-details">
                    <div className="category d-flex justify-content-between">
                      <span className="card-category">Rejection reason</span>
                      <div className='pointer' onClick={() => navigate("/rejection-reason", {state: {
                        rejectionReason: trade.rejectionReason,
                        rejectAttachments: trade.rejectAttachments,
                      }})}>     
                        <span className="see-why">. See why</span>
                      </div>
                    </div>
                  </div>
                )}

                <div className="confirm-details">
                  <div className="category d-flex justify-content-between">
                    <span className="card-category">Images</span>
                  </div>
                </div>

                {trade.status.toLowerCase() === 'paid' && (
                  <div>
                    <div className="paid-text pt-3">Paid</div>
                    <div className="d-flex justify-content-center">
                      <img src={Paid} alt="paid" />
                      <img src={PaidIcon} alt="paid-icon" />
                    </div>
                  </div>
                )}

                {trade.status.toLowerCase() === 'pending' && (
                  <div>
                    <div className="pending-text pt-3">Pending</div>
                    <div className="d-flex justify-content-center">
                      <img src={Pend} alt="paid" />
                      <img src={PendingIcon} alt="paid-icon" />
                    </div>
                  </div>
                )}

                {trade.status.toLowerCase() === 'rejected' && (
                  <div>
                    <div className="rejected-text pt-3">Rejected</div>
                    <div className="d-flex justify-content-center">
                      <img src={Rejected} alt="paid" />
                      <img src={RejectedIcon} alt="paid-icon" />
                    </div>
                  </div>
                )}
                
              </div>
            </div>
          )}
        </div>

        
      </section>
  </>
  )
}

export default TradeStatusDetails