import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import TradeHeader from './TradeHeader';
import TermsModal from './../modals/TermsModal';
import Axios from '../utils/axios';
import Bitcoin from '../assets/images/btcoin.svg';
import Tether from '../assets/images/usd.svg';
import Cedis from '../assets/images/cedis.svg';
import Naira from '../assets/images/naira.svg';
import { Triangle } from  'react-loader-spinner';


const toBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = error => reject(error);
});

const uploadImages = async image => {
  try {
    let response = await Axios.post('signed-storage-url', {
      content_type: `image/${image.type}`
    });
    const {data} = response;

    if (data?.url) {
      const base64Image = await toBase64(image);
      try {
        await Axios.put(data?.url, base64Image, {
          headers: {
            'Content-Type': `image/${image.type}`
          }
        });

        return {uuid: data?.uuid, key: data?.key};
      } catch (error) {
        console.log(error);
        throw error;
      }
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const filterTradeImage = async selectedImages => {
  const filteredImage = await Promise.all(
    selectedImages.map(async item => {
      try {
        const imageUploadRes = await uploadImages(item);

        return {...imageUploadRes, ext: item?.type.split('/').pop()};
      } catch (error) {throw error}
    }),
  );

  return filteredImage;
};



function Confirmation() {
  const [loading, setLoading] = useState(false);
  const {state} = useLocation();
  const navigate = useNavigate();
  const [ terms, setTerms] = useState(false);

  useEffect(() => {
    if (!state?.email) {
      navigate('/trading');
    }
  }, [state]);
  const images = state?.images || [];

  const Terms = () => {
    setTerms( prev => !prev);
  }

  const renderIcon = () => {
    switch (state?.payoutMethod) {
      case "CEDIS":
        return Cedis;
      case "BITCOINS":
        return Bitcoin;
      case "USDT":
        return Tether;
      case "NAIRA":
      default:
        return Naira;
    }
  }

  const handleSubmit = async () => {
    setLoading(true);
    console.log('statestate', state);
    try {
      const {
        email,
        ecode,
        units,
        giftCard,
        payoutMethod,
        payoutBankID,
        payoutBankName,
        payoutAccountNumber,
        payoutAccountName,
        payoutAddress,
        images
      } = state;
      const tradeImages = await filterTradeImage(images);
  
      const formData = {
        email,
        ecode,
        units: parseInt(units),
        giftCard,
        payoutMethod,
        payoutBankID,
        payoutBankName,
        payoutAccountNumber,
        payoutAccountName,
        payoutAddress,
        images: tradeImages,
      };
      console.log('formData', formData);

      const res = await Axios.put('/trade', formData);
      console.log('resres', res);
      if(res.status === 201) {
        console.log("trade was successful")
        navigate('/success', {state: res.data});

      }
      setLoading(false);
      
    } catch (error) {
      setLoading(false);
      console.log('error', error);
    }
  }

  return (
    <>
      <TradeHeader />

      <section id='confirmation' className='animate__animated animate__zoomIn animate__faster mb-5'>
        { terms && <TermsModal Terms={ Terms }/>}
        <div className="container">

          <div className="row">
            <div className="col-10 col-sm-10 col-md-6 m-auto">
              <div className="confirm-header">Confirm this for us.</div>

              <div className="confirm-details">
                <div className="category d-flex justify-content-between">
                  <span className="card-category">Gift card category</span>
                  <span className="value">{state?.giftCardCategory}</span>
                </div>
              </div>

              <div className="confirm-details">
                <div className="category d-flex justify-content-between">
                  <span className="card-category">Gift card type</span>
                  <span className="value">{state?.giftCardType}</span>
                </div>
              </div>

              <div className="confirm-details">
                <div className="category d-flex justify-content-between">
                  <span className="card-category">Gift card Amount</span>
                  <span className="value text-green">{state?.units}</span>
                </div>
              </div>

              <div className="confirm-details">
                <div className="category d-flex justify-content-between">
                  <span className="d-flex align-items-center">
                    <p className="card-category">Payment method <br /> 
                      <span className="value">  {state?.payoutMethod}</span>
                    </p>
                  </span>
                  <span className="value">
                    <img src={renderIcon()} alt="payment method" style={{height: '45px', width: '45px'}} />
                  </span>
                </div>
              </div>

              <div className="confirm-details">
                <div className="category d-flex justify-content-between">
                  <span className="card-category">You will be PAID <br /> <span className='value'>Rate - {state?.conversionRate}</span></span>
                  <span className="value text-green">{state?.paymentAmount}</span>
                </div>
              </div>

              <div className="confirm-details">
                <div className="category d-flex justify-content-between">
                  <span className="card-category">Comments:</span>
                  <span className="text-blue">{state?.ecode}</span>
                </div>
              </div>

              <div className="confirm-details">
                <div className="category d-flex justify-content-between mb-3">
                  <span className="card-category">Images</span>
                </div>
                  {images.map((item, index) => (
                    <img key={index.toString()} src={URL.createObjectURL(item)} className={`trading-image ${index ? '' : 'me-2'}`} />
                  ))}
              </div>

              <a onClick={ Terms } to="#" className='trade-conditions-link pointer'><div>Reveal Trade Conditions</div></a>

              <div className="d-flex justify-content-center">
                <button className="shap-shap-btn-black d-block d-sm-block d-md-none"
                  onClick={handleSubmit}
                >
                {loading && <span className="spinner"><Triangle height="30" width="30" color='white' ariaLabel='loading' className="spinner" /></span>}
                {!loading && 'Confirm Trade'}
                </button>
                <button
                  className="shap-shap-btn-trade d-none d-md-block d-lg-block d-xl-block"
                  onClick={handleSubmit}
                >
                  {loading && <span className="spinner"><Triangle height="30" width="30" color='white' ariaLabel='loading' className="spinner" /></span>}
                  {!loading && 'Confirm Trade'}
                </button>
              </div>
            </div>
          </div>
        </div>

        
      </section>
    </>
  )
}

export default Confirmation