import React from 'react';
import Button from '../assets/images/submit.svg';

function Overlay() {
  return (
    <div className='main-overlay'>
      <div className="d-flex justify-content-center align-items-center main-overlay-btn">
        <p className='continue-btn mx-3'>Continue</p>
        <img src={Button} alt="continue" className='mx-2' />
      </div>
    </div>
  )
}

export default Overlay