import React from 'react'
import { Link } from 'react-router-dom';
import Logo from '../assets/images/logo.svg';

function ErrorHeader() {
  return (
    <nav className="navbar navbar-expand-lg header">
      <div className="container">
        <Link className="navbar-brand navbar-brand-trade" to="/"><img src={Logo} className="logo" alt="logo" /></Link>
      </div>
    </nav>
  )
}

export default ErrorHeader