import React, { useState, useEffect } from 'react';
import TradeHeader from '../pages/TradeHeader';
import TradeBull from '../assets/images/trade-bull.svg';


function EmailTooltip() {
  const [email, setEmail] = useState('');
  const [showOverlay, setShowOverlay] = useState(true);

  useEffect(() => {
    if (email.length && showOverlay) {
      setShowOverlay(false);
    }
  }, [email, showOverlay]);

  const handleOverLayClick = (evt) => {
    evt.preventDefault();
    setShowOverlay(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault()
  }


  return (
    <>
      <TradeHeader />

      {showOverlay && <a className='tooltip-overlay' onClick={handleOverLayClick} />}

      <div id="Trade">
        <div className="container">
          <div className="row">
            <div className="col-10 col-sm-10 col-md-6 col-lg-6 m-auto">
              <div className='text-center trade-bull-container'>
                <img src={TradeBull} alt="trade bull" className="trade-bull text-center" /> 
                <div className="trade-bull-header">Your Email Mr. Annonymous</div>
                <div className="trade-bull-text">Just so you can get notified.</div>
              </div>

              <div className="register">
                <form className='text-center' onSubmit={handleSubmit}>
                  {showOverlay && <div className='tooltip-content'>
                    <p>Please enter a valid email address, note that transaction updates will be sent to this email.</p>
                    <span className='caret down' />
                  </div>}
                  <input autoFocus placeholder="Email Address" type="email" className='email position-relative bg-transparent' value={email} onChange={(event) => setEmail(event.target.value)} />
                  <div className='d-flex justify-content-center'>
                    <button className="shap-shap-btn-black d-block d-sm-block d-md-none">Start Trade</button>
                    <button className="shap-shap-btn-trade d-none d-md-block d-lg-block d-xl-block">Start Trade</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default EmailTooltip