import React, { useEffect, useRef, useState } from 'react';
import LottiePlayer from 'lottie-react';
import animationData from '../lotties/upload.json';
import TradeHeader from './TradeHeader';
import Bitcoin from '../assets/images/btcoin.svg';
import BitcoinInactive from '../assets/images/bitcoin-inactive.svg';
import Tether from '../assets/images/usd.svg';
import TetherInactive from '../assets/images/tether-inactive.svg';
import Cedis from '../assets/images/cedis.svg';
import CedisInactive from '../assets/images/cedis-inactive.svg';
import Naira from '../assets/images/naira.svg';
import NairaInactive from '../assets/images/naira-inactive.svg';
import SubmitButton from '../assets/images/submit.svg';
import Axios from '../utils/axios';
import { useLocation, useNavigate } from 'react-router-dom';


function Trading() {
  const {state} = useLocation();
  const fileRef = useRef();
  const email = state?.email;
  const navigate = useNavigate();
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  const [active, setActive] = useState('bitcoin');
  const [selectedGiftCardCategory, setSelectedGiftCardCategory] = useState('');
  const [selectedGiftCardType, setSelectedGiftCardType] = useState('');
  const [nigerianBanks, setNigerianBanks] = useState([]);
  const [giftCardCategory, setGiftCardCategory] = useState([]);
  const [conversionMatrix, setConversionMatrix] = useState({
    BITCOINS: 1,
    USDT: 1,
    CEDIS: 1,
  });
  const [showOverlay, setShowOverlay] = useState(false);
  const [overlayStep, setOverlayStep] = useState('cardCategory');
  const [cardType, setCardType] = useState([]);
  const [files, setFiles] = useState([]);

  //trade state
  const [units, setUnits] = useState('0.00');
  const [usdt, setUsdt] = useState('')
  const [payoutBankName, setPayoutBankName] = useState('');
  const [payoutAccountNumber, setPayoutAccountNumber] = useState('');
  const [payoutAccountName, setPayoutAccountName] = useState('');
  const [payoutAddress, setPayoutAddress] = useState(''); 
  const [ecode, setEcode] = useState('');
  
  useEffect(() => {
    if (!email) {
      navigate('/trade');
    }
  }, [email]);

  useEffect(() => {
    if (!window.localStorage.getItem('shown_trading_overlay')) {
      setShowOverlay(true);
    }
    
    Axios.get('context')
    .then( response => {
      console.log(response.data)
      setNigerianBanks(response.data.nigerianBanks)
      setGiftCardCategory(response.data.giftcardCategories)
      setConversionMatrix(response.data.conversionMatrix)
    } )
    .catch( err => err.message)  
  }
    , 
    []);

  useEffect(() => {
    const newGiftCardTypes = giftCardCategory.find(item => item.name === selectedGiftCardCategory)?.giftcards;

    setCardType(newGiftCardTypes || []);
    setSelectedGiftCardType('');
  }, [selectedGiftCardCategory, giftCardCategory]);

  const handleOverlayContinue = () => {
    switch (overlayStep) {
      case 'cardCategory':
        setOverlayStep('cardType');
        break;
      case 'cardType':
        setOverlayStep('cardAmount');
        break;
      case 'cardAmount':
        setOverlayStep('paymentMethod');
        break;
      case 'paymentMethod':
        setOverlayStep('paymentInfo');
        break;
      case 'paymentInfo':
        setOverlayStep('uploadImage');
        break;
      case 'uploadImage':
      default:
        window.localStorage.setItem('shown_trading_overlay', true);
        setShowOverlay(false);
        break;
    }
  }

  const handleFileSelect = () => {
    const inputFile = fileRef.current.files[0];
    setFiles(stateFiles => [...stateFiles, inputFile]);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if(giftCardCategory.value === "") {
      console.log("giftcard is empty")
    }
    let payoutMethod = active.toUpperCase();
    payoutMethod = active === 'tether' ? "USDT" : payoutMethod;
    let payoutBankID = '';
    let address = '';
    let paymentAmount = units;
    let conversionRate = 1;

    if (payoutBankName) {
      const bank = nigerianBanks.find(item => item.name === payoutBankName);

      payoutBankID = bank?.id || '';
    }

    switch (active) {
      case 'bitcoin':
        paymentAmount = units / conversionMatrix.BITCOINS;
        conversionRate = conversionMatrix.BITCOINS;
        payoutMethod = "BITCOINS";
        address = payoutAddress;
        break;
      case 'tether':
        paymentAmount = units / conversionMatrix.USDT;
        conversionRate = conversionMatrix.USDT;
        payoutMethod = "USDT";
        address = usdt;
        break;
      case 'cedis':
        paymentAmount = units / conversionMatrix.CEDIS;
        conversionRate = conversionMatrix.CEDIS;
        break;
      default:
        break;
    }

    const giftCardType = cardType.find(item => item.id.toString() === selectedGiftCardType.toString())?.name || '';

    let formData = {
      units,
      giftCard: {id: selectedGiftCardType},
      email,
      ecode,
      payoutMethod,
      payoutBankName,
      payoutAccountNumber,
      payoutAccountName,
      payoutAddress: address,
      payoutBankID,
      images: files,
      giftCardCategory: selectedGiftCardCategory,
      giftCardType,
      paymentAmount,
      conversionRate,
    }
    navigate('/confirmation', { state: formData });
    console.log({
      giftCardCategory: selectedGiftCardCategory,
      units,email,
    })



  }
          
  return (
    <>
      <TradeHeader/>

      {showOverlay && (
        <div className='main-overlay'>
          <button
            className="d-flex justify-content-center align-items-center main-overlay-btn"
            onClick={handleOverlayContinue}
          >
            <p className='continue-btn mx-3'>Continue</p>
            <img src={SubmitButton} alt="continue" className='mx-2' />
          </button>
        </div>
      )}

      <section id="trading" className={showOverlay ? '' : ''}>
        <div className="container">
          <div className="row">
            <div className='col-10 col-sm-10 col-md-6 col-lg-6 m-auto'>
              <div className={showOverlay && overlayStep === 'cardCategory' ? 'position-relative' : ''}>
                <select
                  className={`select mb-3 text-blue ${showOverlay && overlayStep === 'cardCategory' ? 'position-relative' : ''}`}
                  onChange={(e) => setSelectedGiftCardCategory(e.target.value)}
                  >
                  <option defaultValue>Gift Card Category</option>
                  {giftCardCategory.map(category => (
                    <option key={category.id}>{category.name}</option>
                  ))}
                </select>

                {showOverlay && overlayStep === 'cardCategory' && <div className='tooltip-content trading up position-absolute'>
                  <span className='caret up' />
                  <p>This is where you select the brand name of the gift card you are selling.</p>
                </div>}
              </div>
              
              <div className={showOverlay && overlayStep === 'cardType' ? 'position-relative' : ''}>
                <select
                  className='select mb-3 text-blue'
                  onChange={(e) => setSelectedGiftCardType(e.target.value)}
                  >
                  <option defaultValue>Gift Card Type</option>
                  
                  {cardType.map( type => (
                    <option key={type.id} value={type.id}>{type.name}</option>
                  ))
                  }
                  
                  
                </select>

                {showOverlay && overlayStep === 'cardType' && <div className='tooltip-content trading up position-absolute'>
                  <span className='caret up' />
                  <p>
                    This is where you will further specify what “type” of gift card you have and want to sell. 
                    <br /><br />
                    For example: 
                    <br /><br />
                    USA GooglePlay Physical (50-100). Too much grammar? Don’t be confused. Let’s break it down for you: 
                    <br /><br />
                    What this simply means is that your GooglePlay card is from USA, physical means you have a picture of it (If you don’t have a picture but you have the code, it’ll be ECODE), and 50-100 means that your iTunes card amount is within 50 to 100 dollars.
                  </p>
                </div>}
              </div>

              <div className={`amount text-center ${showOverlay && overlayStep === 'cardAmount' ? 'position-relative' : ''}`}>
                <p className='amount-text'>Gift Card Amount</p>
                <input className='amount-form' type="number" placeholder='0.00'  onChange={ e => setUnits(e.target.value)}  />

                {showOverlay && overlayStep === 'cardAmount' && <div className='tooltip-content trading up position-absolute'>

                  <span className='caret up' />
                  <p>This is where you enter the total amount of gift cards you want to sell. If you are selling a $100 iTunes gift card in 5 pieces, that means the total amount is 500.</p>
                </div>}
              </div>

              <div className="payments">
                <p className='payments-text text-blue'>How do you want payment?</p>

                <div className={`container my-4 payment-options ${showOverlay && overlayStep === 'paymentMethod' ? 'position-relative' : ''}`}>
                {showOverlay && overlayStep === 'paymentMethod' && <div className='tooltip-content trading position-absolute payment-overlay'>
                  <p>How do you want to receive payment?</p>
                  <span className='caret down' />
                </div>}
                  <div className="d-flex justify-content-around">
                    <div className="col-3 col-md-3" onClick={() => setActive('bitcoin')}>
                      <img src={active === 'bitcoin' ? Bitcoin : BitcoinInactive} alt="bitcoin" />
                    </div>
                    <div className="col-3 col-md-3" onClick={() => setActive('tether')}>
                      <img src={active === 'tether' ? Tether : TetherInactive} alt="tether" />
                    </div>
                    <div className="col-3 col-md-3" onClick={() => setActive('cedis')}>
                      <img src={active === 'cedis' ? Cedis : CedisInactive} alt="cedis" />
                    </div>
                    <div className="col-3 col-md-3" onClick={() => setActive('naira')}>
                      <img src={active === 'naira' ? Naira : NairaInactive} alt="naira" />
                    </div>
                  </div>
                </div>

                  <form onSubmit={handleSubmit}>

                
                {
                  active === 'bitcoin' && <div>
                    <div className='coin-type text-center'><p className='animate__animated animate__zoomIn animate__faster'> Bitcoin - BTC </p></div>
                    <div className={showOverlay && overlayStep === 'paymentInfo' ? 'position-relative' : ''}></div>
                      <input className={`btc-address-form ${showOverlay && overlayStep === 'paymentInfo' ? 'position-relative' : ''}`} type="text" placeholder='BTC Address' value={payoutAddress} onChange={(e) => setPayoutAddress(e.target.value)}/>
                      {showOverlay && overlayStep === 'paymentInfo' && <div className='tooltip-content tooltip-content-address trading up position-absolute'>
                  <span className='caret up' />
                  <p>Enter your payout information here. Ensure that the info entered is correct to avoid payment disputes or delay. Your payment will be forwarded here immediately the transaction is completed.</p>
                </div>}
                      <p className="validate">Please, Enter the Correct BTC Address!</p>
                      <input className="shap-shap-btn shap-shap-btn-black" type="submit" value="Save Address"/> 

                      <div className="total-amount text-blue d-flex justify-content-between container">
                        <p>You will be PAID</p>
                        <p>{units / conversionMatrix.BITCOINS} BTC</p>
                      </div>
                  </div>
                }

                {
                  active === 'tether' && <div>
                  <div className='coin-type text-center'><p className='animate__animated animate__zoomIn animate__faster'> Tether - USDT </p></div>
                      <input className='btc-address-form' type="text" placeholder='USDT Address' value={usdt} onChange={ (e) => {setUsdt(e.target.value)} } />
                      <p className="validate">Please, Enter the Correct USDT Address!</p>
                      <input className="shap-shap-btn shap-shap-btn-black" type="submit" value="Save Address" />

                      <div className="total-amount text-blue d-flex justify-content-between container">
                        <p>You will be PAID</p>
                        <p>{units / conversionMatrix.USDT} USDT</p>
                      </div>
                  </div>
                }

                {
                  active === 'cedis' && <div>
                  <div className='coin-type text-center'><p className='animate__animated animate__zoomIn animate__faster'>Cedis - GHS</p></div>
                      <input className='btc-address-form' type="text" placeholder='Enter Bank Name' value={payoutBankName} onChange={ (e) => setPayoutBankName(e.target.value) } required/>
                      <input className='btc-address-form' type="number" placeholder='Account Number' value={payoutAccountNumber} onChange={ (e) => setPayoutAccountNumber(e.target.value) } required/>
                      <input className='btc-address-form' type="text" placeholder='Enter Full Name' value={payoutAccountName} onChange={ (e) => setPayoutAccountName(e.target.value) } required/>
                      <input className="shap-shap-btn shap-shap-btn-black" type="submit" value="Save Details"/>

                      <div className="total-amount text-blue d-flex justify-content-between container">
                        <p>You will be PAID</p>
                        <p>{
                          (units / conversionMatrix.CEDIS).toFixed(2)
                        } GHS</p>
                      </div>
                  </div>
                }

                {
                  active === 'naira' && <div className="">
                  <div className='coin-type text-center'><p className='animate__animated animate__zoomIn animate__faster'> Naira - NGN </p>
                  </div>
                    <div className={showOverlay && overlayStep === 'paymentInfo' ? 'position-relative' : ''}></div>
                      <select className="select my-3 text-blue" value={payoutBankName} onChange={ (e) => {
                        setPayoutBankName(e.target.value)
                      } }>
                        <option defaultValue>Select bank</option>
                        { nigerianBanks.map( (bank) =>  <option key={bank.id}>{ bank.name }</option>) }
                      </select> 
                         
                      <input className='btc-address-form' type="number" placeholder='Account Number' value={payoutAccountNumber} onChange={ (e) => {
                        setPayoutAccountNumber(e.target.value)
                      }} required/>

                      <input className='btc-address-form' type="text" placeholder='Account Name' value={payoutAccountName} onChange={ (e) => {
                        setPayoutAccountName(e.target.value)
                      } } required/>

                      <input className="shap-shap-btn shap-shap-btn-black" type="submit" value="Save Details" />

                      <div className="total-amount text-blue d-flex justify-content-between container">
                        <p>You will be PAID</p>
                        <p>{units} NGN</p>
                      </div>
                  </div>
                }
                
                      
                <div className={`upload-image ${showOverlay && overlayStep === 'uploadImage' ? 'position-relative' : ''}`}>
                  <input ref={fileRef} accept="image/*" type="file" id="defult-btn" hidden onChange={handleFileSelect} />
                  <div className={`amount text-center ${showOverlay && overlayStep === 'uploadImage' ? 'position-relative' : ''}`}></div>
                  <div className="upload-image bg-light d-flex justify-content-center align-item-center">
                    {!files.length ? <LottiePlayer className='lottie' {...defaultOptions}/> : files.map((item, index) => (
                      <img key={index.toString()} src={URL.createObjectURL(item)} className={`trading-image ${index ? 'ms-2' : ''}`} />
                    ))}
                  </div>
                  <button className={`upload-image-text ${showOverlay && overlayStep === 'uploadImage' ? 'text-light' : ''}`} id="custom-btn" type='button'
                  onClick={() => fileRef?.current?.click()}>Upload Images</button>
                  {showOverlay && overlayStep === 'uploadImage' && <div className='tooltip-content trading up position-absolute image-upload'>
                    <span className='caret up' />
                  <p>This is where you upload pictures of your gift card. You can upload up to 25 pictures in a single trade. If you are trading ecode, that doesn’t require a picture, you can leave this field empty then enter the ecode in the comment section.</p>
                  </div>
                  }
                </div>

                <div className="e-code">
                  <p className="e-code-text">Enter Code (Only If ECODE)</p>
                  <input className='e-code-form' type="text" placeholder='E-code' value={ecode} onChange={(e) => {
                    setEcode(e.target.value)
                  }} />
                  <div className="submit d-flex justify-content-end align-items-center">
                    <div className='mx-3 submit-text'>Submit</div>
                    <button className='submit-btn' type='submit'>
                      <img src={SubmitButton} alt="submit" />
                    </button>
                  </div>
                </div>
              </form>

              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Trading